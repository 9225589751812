<template>
  <v-app-bar
    app
    rounded
    :fade-img-on-scroll="appBarSettingFadeImageOnScroll"
    :shrink-on-scroll="appBarSettingShrinkOnScroll"
    :dark="dark"
    :color="appBarSettingColorClass"
    :clipped-left="clippedLeft"
    :collapse="collapse"
    :collapse-on-scroll="appBarSettingCollapseOnScroll"
    :src="appBarBackgroundImageSrc"
    :clipped-right="clippedRight"
    :prominent="appBarSettingProminent"
  >
    <!-- Toggle Navigation Drawers -->
    <slot name="navIcon">
      <v-app-bar-nav-icon
        v-if="!visibleAvatar"
        @click.stop="setDrawer(!drawer)"
      >
        <template v-slot:default>
          <v-avatar tile>
            <v-img :src="avatarImgSrc" contain></v-img>
          </v-avatar>
        </template>
      </v-app-bar-nav-icon>
    </slot>

    <!-- Customize Background Image -->
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        :gradient="appBarSettingGradient"
        v-if="appBarSettingGradient"
      ></v-img>
    </template>

    <!-- App Logo -->
    <slot name="logo">
      <v-toolbar-title>
        <v-avatar
          v-show="visibleAvatar"
          :size="avatarSize"
          :style="avatarStyle"
          tile
        >
          <v-img :src="avatarImgSrc" contain></v-img>
        </v-avatar>
        <span v-show="visibleTitle">{{ title }}</span>
      </v-toolbar-title>
    </slot>

    <v-spacer></v-spacer>

    <!-- User Menu -->
    <slot name="user">
      <user-menu v-if="!isPublicAccess"></user-menu>

      <!-- Popover Menu About -->
      <popover-menu-about-public-access
        v-else
        v-model="visiblePopoverMenuAbout"
        @close="closePopoverMenuAbout"
      >
      </popover-menu-about-public-access>
    </slot>

    <!-- Application Navigation -->
    <template v-slot:extension>
      <template v-if="visibleApplicationNavigation">
        <!-- application-tabs if appBar Setting Uses AppTabs -->
        <application-tabs
          v-if="appBarSettingUsesAppTabs"
          center-active
          show-arrows
        ></application-tabs>

        <!-- application menu -->
        <app-menu v-else></app-menu>
      </template>
    </template>
  </v-app-bar>
</template>

<script>
// design
import { iconClose, iconInfo } from "@/design/icon/iconConst";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } = createNamespacedHelpers("solution");
import { SET_DRAWER, SET_DRAWER_RIGHT } from "@/store/solution/mutation-types";

//mixins
import { appBarMixin } from "@/mixins/shared/base/appBar/appBarMixin";

export default {
  name: "AppBar",
  mixins: [appBarMixin],
  components: {
    ApplicationTabs: () =>
      import("@/components/shared/core/tabs/ApplicationTabs"),
    AppMenu: () => import("@/components/shared/core/menu/AppMenu"),
    UserMenu: () => import("@/components/shared/core/menu/UserMenu"),
    PopoverMenuAboutPublicAccess: () =>
      import(
        "@/components/shared/core/menu/popover/PopoverMenuAboutPublicAccess"
      )
  },
  data() {
    return {
      visiblePopoverMenuAbout: false,
      iconClose,
      iconInfo
    };
  },
  computed: {
    ...mapGetters({
      visibleLeftDrawer: "visibleLeftDrawer",
      visibleRightDrawer: "visibleRightDrawer"
    }),
    drawer: {
      get() {
        return this.visibleLeftDrawer;
      }
    },
    drawerRight: {
      get() {
        return this.visibleRightDrawer;
      }
    },
    /**
     * v-app-bar clipped left
     * Note: Designates that the appModule's v-navigation-drawerLeft that is positioned on the left is below the solution-bar
     * @returns { boolean }
     */
    clippedLeft() {
      return this.visibleOnMdAndUp;
    },

    menuLabelAbout() {
      return "Contact";
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: SET_DRAWER,
      setDrawerRight: SET_DRAWER_RIGHT
    }),
    closePopoverMenuAbout() {
      console.log(`closePopoverMenuAbout()`);
      this.visiblePopoverMenuAbout = false;
    }
  }
};
</script>
